import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, mergeMap, retryWhen, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpWrapperService {
  private _baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(Injector) private injector: Injector,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this._baseUrl = baseUrl;
  }

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  private formatErrors(error: any) {
    if (error.error.errors) {
      console.log(error.error.errors)
      for (let err in error.error.errors) {
        this.toastrService.error(error.error.errors[err], err);
      };
    }
    else {
      this.toastrService.error(error?.error?.message ?? error?.error ?? error);
    }
    return throwError(error);
  }

  private delayedRetry() {
    let retries = 2;
    return (src: Observable<any>) =>
      src.pipe(
        retryWhen((errors: Observable<any>) => errors.pipe(
          delay(1000),
          mergeMap(error => {
            console.log(error)
            if (error?.error?.statusCode == 429) {
              return retries-- > 0 ? of(error) : throwError(error)
            }
            else {
              return throwError(error)
            }
          })
        ))
      )
  }

  public get(path: string): Observable<any> {
    return this.http.get(`${this._baseUrl}api/${path}`)
      .pipe(
        this.delayedRetry(),
        catchError(err => this.formatErrors(err))
      );
  }

  public post(path: string, body: Object = {}, options: any = {}): Observable<any> {
    return this.http.post<any>(
      `${this._baseUrl}api/${path}`,
      body,
      options
    )
      .pipe(
        this.delayedRetry(),
        catchError(err => this.formatErrors(err))
      );
  }

  public put(path: string, body: Object = {}, options: any = {}): Observable<any> {
    return this.http.put<any>(
      `${this._baseUrl}api/${path}`,
      body,
      options
    )
      .pipe(
        this.delayedRetry(),
        catchError(err => this.formatErrors(err))
      );
  }

  public delete(path: string, body: Object = {}, options: any = {}): Observable<any> {
    return this.http.delete<any>(
      `${this._baseUrl}api/${path}`,
      body
    )
      .pipe(
        tap(_ => console.log(`delete`)),
        this.delayedRetry(),
        catchError(err => this.formatErrors(err))
      );
  }
}
