
export const CUSTOMER_DETAILS = (disabled = false) => ({
  id: 'CUSTOMER_DETAILS',
  template: [
    {
      wrappers: ['panel'],
      templateOptions: {
        label: 'Formly'
      },
      fieldGroup: [
        { key: 'firstName', type: 'input', templateOptions: { label: 'First Name', placeholder: 'First Name', required: true } },
        { key: 'lastName', type: 'input', templateOptions: { label: 'Last Name', placeholder: 'Last Name', required: true } },
        { key: 'company', type: 'input', templateOptions: { label: 'Company', placeholder: 'Company', disabled: true } },
        { key: 'email', type: 'input', templateOptions: { label: 'Email', placeholder: 'Email', required: true } },
        { key: 'phone', type: 'input', templateOptions: { label: 'Phone', placeholder: 'Phone' } },
        { key: 'mobile', type: 'input', templateOptions: { label: 'Mobile', placeholder: 'Mobile' } },
        { key: 'address1', type: 'input', templateOptions: { label: 'Address 1', placeholder: 'Address 1', required: true } },
        { key: 'address2', type: 'input', templateOptions: { label: 'Address 2', placeholder: 'Address 2' } },
        { key: 'city', type: 'input', templateOptions: { label: 'City', placeholder: 'City', required: true } },
        { key: 'postCode', type: 'input', templateOptions: { label: 'PostCode', placeholder: 'PostCode', required: true } },
      ]
    }
  ]
});
