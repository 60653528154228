import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CategoryModel } from 'src/app/core/models';
import { CategoryService } from 'src/app/core/services';
import { Breadcrumb } from './../../core/models/breadcrumb';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {
  @Input() title: string;
  @Input() breadcrumb: boolean = false;
  public categories$: Observable<CategoryModel[]>;
  public breadcrumbs$: Observable<Breadcrumb[]> = this.categoryService.breadcrumbs$;

  constructor(
    private categoryService: CategoryService,
    private router: Router
  ) {
  }

  ngOnInit() {

  }

  clicked(breadcrumb: Breadcrumb) {
    this.router.navigateByUrl(breadcrumb.path)
  }
}
