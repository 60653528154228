import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LineItem, ProductOption } from 'src/app/core/models';
import { ProductModel } from '../models/product';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root',
})
export class ProductOptionService {
  private product: ProductModel;
  private sizes: string[];
  private colours: string[];
  private extraOptions: string[];
  private displayedColumns: Array<string>;
  private cartItems: LineItem[];
  private hasNameLabel: boolean = false;
  private canPersonalise: boolean = false;
  private evolveHO: boolean = false;

  private selectedColour: string;
  private colourSelectedSubject$ = new BehaviorSubject<string>('');
  public colourSelected$ = this.colourSelectedSubject$.asObservable();

  private optionSelectedSubject$ = new BehaviorSubject<boolean>(false);
  public optionSelected$ = this.optionSelectedSubject$.asObservable();

  private cartItemsSubject$ = new BehaviorSubject<LineItem[]>([]);
  public cartItems$ = this.cartItemsSubject$.asObservable();

  constructor(private cartService: CartService) { }

  set(product: ProductModel) {
    this.clear(true);
    this.product = product;
    this.setProductOptions()
  }

  get() {
    return {
      hasOptions: this.product.productOptions.length > 0,
      orderType: this.product.orderType,
      sizes: this.sizes,
      colours: this.colours,
      extraOptions: this.extraOptions,
      displayedColumns: this.displayedColumns,
      hasNameLabel: this.hasNameLabel,
      canPersonalise: this.canPersonalise,
      evolveHO: this.evolveHO,
      cartItems: this.cartItems
    };
  }

  nameChanged(name: string, index: number) {
    this.cartItems[index].option2 = name;
    this.cartItemsSubject$.next(this.cartItems);
  }

  optionChanged(option: ProductOption, quantity: number) {
    if (quantity <= 0) {
      quantity = 0;
      const index = this.cartItems.findIndex((o) => this.cartService.itemCompare(o, option.productOptionCode, option.option1, '', option.size));
      this.cartItems.splice(index, 1);
    }
    else {

      let cartItem: LineItem;
      if (this.product.optionLabel2 === 'Name') {
        let itemsPresent: number = 0;
        // remove last item if quantity decreased
        this.cartItems = this.cartItems.filter(item => {
          if (this.cartService.itemCompare(item, option.productOptionCode, option.option1, option.option2, option.size)) {
            itemsPresent++;
            return itemsPresent <= quantity;
          }
          else {
            return true;
          }
        });
        // add individual items (quantity=1) * quantity so name can be set for each option
        for (let i = itemsPresent; i < quantity; i++) {
          cartItem = this.createCartItem(option, 1);
          this.cartItems.push(cartItem);
        }
      }
      else if (this.product.optionLabel2 === 'Personalise' || this.product.optionLabel2 === 'EvolveHO') {
        if (quantity > 0) {
          const index = this.cartItems.findIndex((o) => this.cartService.itemCompare(o, option.productOptionCode, option.option1, '', option.size))
          cartItem = this.createCartItem(option, quantity);
          if (index === -1) {
            this.cartItems.push(cartItem);
          } else {
            this.cartItems[index].qty = quantity;
          }
        }
      }
      else {
        const index = this.cartItems.findIndex((o) => this.cartService.itemCompare(o, option.productOptionCode, option.option1, option.option2, option.size))
        cartItem = this.createCartItem(option, quantity);
        if (index === -1) {
          this.cartItems.push(cartItem);
        } else {
          this.cartItems[index] = cartItem;
        }
      }
    }

    this.cartItemsSubject$.next(this.cartItems);
    this.optionSelectedSubject$.next(this.cartItems.length > 0);
  }

  private createCartItem(option: ProductOption, quantity: number): LineItem {
    return {
      code: option.productOptionCode,
      id: 0,
      qty: quantity,
      name: this.product.name,
      size: option.size,
      option1: option.option1,
      option2: option.option2,
      option3: option.size ? `${quantity} x ${option.size}` : '',
      styleCode: this.product.styleCode,
      unitPrice: option.price,
      _updating: false
    } as LineItem
  }

  getOption(size: string, colour: string): ProductOption {
    let option = this.product.productOptions.find(option => option.option1 === colour && (option.size === size || size === ''));
    return option;
  }

  getExtraOption(extra: string, colour: string, ignoreColourSelection: boolean = false): ProductOption {
    if (this.selectedColour || ignoreColourSelection) {
      return this.product.productOptions.find(option => option.option1 === colour && option.option2 === extra);
    }
    else {
      return this.product.productOptions.find(option => option.option1 === '' && option.option2 === extra);
    }
  }

  getSelectedExtraOption(extra: string): ProductOption {
    return this.getExtraOption(extra, this.selectedColour);
  }

  getSelectedOption(size: string): ProductOption {
    let option;
    if (this.selectedColour) {
      option = this.product.productOptions.find(option => (option.option1 === this.selectedColour) && (option.size === size || size === ''));
    }
    else {
      option = this.product.productOptions.find(option => (option.option1 === '') && (option.size === size || size === ''));
    }
    return option;
  }

  selectColour(colour: string) {
    this.selectedColour = colour;
    this.colourSelectedSubject$.next(colour);
  }

  clear(force: boolean = false) {
    this.cartItems = [];
    this.optionSelectedSubject$.next(false);
    this.cartItemsSubject$.next(this.cartItems);
    this.selectedColour = !force && this.colours?.length > 0 ? this.colours[0] : '';
    this.colourSelectedSubject$.next(this.selectedColour);
  }

  private setProductOptions() {
    this.displayedColumns = ['name'];
    this.cartItems = [];
    this.hasNameLabel = this.product.optionLabel2 === 'Name';
    this.canPersonalise = this.product.optionLabel2 === 'Personalise';
    this.evolveHO = this.product.optionLabel2 === 'EvolveHO';

    const allSizes: string[] = [];
    const allColours: string[] = [];
    const allExtraOptions: string[] = [];
    this.product.productOptions.forEach(function (o) {
      if (o.size) allSizes.push(o.size.toUpperCase());
      if (o.option1) allColours.push(o.option1);
      if (o.option2) allExtraOptions.push(o.option2)
    });
    const uniqueExtraOptions = new Set(allExtraOptions);
    this.extraOptions = [...uniqueExtraOptions];

    const uniqueColours = new Set(allColours);
    this.colours = [...uniqueColours];
    if (this.colours.length === 0)
      this.colours.push("");

    const uniqueSizes = new Set(allSizes.sort());

    const sortedSizes = ['XXS', 'XS', 'S', 'SML', 'M', 'MED', 'L', 'LGE', 'XL', '2XL', '3XL', '4XL', '5XL', '6XL', '7XL', '8XL'];
    for (let i = 1; i <= 126; i++) {
      sortedSizes.push(i + '');
      sortedSizes.push(i + 'R');
    }
    const filteredSizes = this.extraOptions.length === 0 ? sortedSizes.filter(x => uniqueSizes.has(x)) : [];
    this.sizes = [...new Set([...filteredSizes, ...uniqueSizes])]; // this will include any sizes not in custom filter

    if (this.extraOptions.length > 0) {
      this.displayedColumns = [...this.displayedColumns, ...this.extraOptions];
    }
    else {
      this.displayedColumns = [...this.displayedColumns, ...this.sizes];
    }
    if (this.displayedColumns.length === 1) {
      // has one or less colours and no extra options
      this.displayedColumns.push('special');
    }

    this.optionSelectedSubject$.next(false);
    this.cartItemsSubject$.next(this.cartItems);
  }
}
