import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryWithPath } from 'src/app/core/models';
import { CategoryService } from 'src/app/core/services';

@Component({
  selector: 'app-altus-category-menu',
  templateUrl: './altus-category-menu.component.html',
  styleUrls: ['./altus-category-menu.component.css']
})
export class AltusCategoryMenuComponent {
  @Input() parentId: number;
  @Input() isChild: boolean = false;
  @Input() isExpanded: boolean = false;

  public categories: Observable<CategoryWithPath[]> = this.categoryService.categories
    .pipe(
      map(cats => cats.filter(c => c.cin7ParentId === this.parentId))
    );

  constructor(
    private categoryService: CategoryService
  ) { }

  clicked(event, category: CategoryWithPath) {
    this.categoryService.categorySelected(category);
    event.stopPropagation();
  }

  hasChildren(category:CategoryWithPath) {
    return this.categoryService.categories.pipe(
      map(cats => cats.some(c => c.cin7ParentId === category.cin7Id))
    );
  }
}
