import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services';
import { FormlyService } from 'src/app/ui-form/formly-service';
import { ChangePassword } from '../../models';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public form = new UntypedFormGroup({});
  isSubmitting = false;
  returnUrl: string = '/';
  error = '';
  public fields: FormlyFieldConfig[];

  public model: any = { password: '', newPassword: '', confirmPassword: '' };

  constructor(
    private formlyService: FormlyService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) {

  }
  ngOnInit(): void {
    this.fields = [
      ...this.formlyService.getFormById('CHANGE_PASSWORD')
    ];
  }

  submit() {
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    const newPassword: ChangePassword = <ChangePassword>{
      password: this.model.password,
      newPassword: this.model.newPassword?.newPassword,
      confirmPassword: this.model.newPassword?.confirmPassword
    }
    this.authenticationService.changePassword(newPassword).subscribe(
      p => {
        this.isSubmitting = false;
        this.toastr.info('Password Changed');
      },
      err => {
        this.isSubmitting = false;
      });
  }

}
