<div gdGap="10px" gdRows grid-auto-rows="1fr">
  <app-title title="Change Password"></app-title>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="submit()" class="form" autocomplete="false">
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
        <div class="form-element">
          <button mat-flat-button color="primary" type="submit" class="button" [disabled]="!form.valid"
            [appShowSpinner]="isSubmitting">Change Password</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>