
export const LOGIN_FORM = (disabled = false) => ({
  id: 'LOGIN_FORM',
  template: [
    {
      wrappers: ['panel'],
      templateOptions: {
        label: 'Formly'
      },
      fieldGroup: [
        {
          key: 'username',
          type: 'input',
          templateOptions: {
            label: 'Username',
            placeholder: 'Username',
            required: true
          }
        },
        {
          key: 'password',
          type: 'input',
          templateOptions: {
            type: 'password',
            label: 'Password',
            placeholder: 'Password',
            required: true
          }
        },
        {
          template: '<p>It looks like this is the first time you have used this site. <br/>Please enter a new password to complete your registration.</p>',
          hideExpression: '!model.registering',
        },
        {
          key: 'newPassword',
          hideExpression: (model: any, formState: any) => {
            return formState.mainModel && !formState.mainModel.registering;
          },
          validators: {
            fieldMatch: {
              expression: (control) => {
                const value = control.value;

                return value?.confirmPassword === value?.newPassword
                  // avoid displaying the message error when values are empty
                  || (!value.confirmPassword || !value.newPassword);
              },
              message: 'Password Not Matching',
              errorPath: 'confirmPassword',
            },
          },
          fieldGroup: [
            {
              key: 'newPassword',
              type: 'input',
              templateOptions: {
                type: 'password',
                label: 'New Password',
                placeholder: 'New Password',
                required: true
              }
            },
            {
              key: 'confirmPassword',
              type: 'input',
              templateOptions: {
                type: 'password',
                label: 'Confirm Password',
                placeholder: 'Confirm Password',
                required: true
              }
            }

          ]
        }
      ],
    },

  ]
});
