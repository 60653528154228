<div class="footer">
  <mat-card>
    <p *ngIf="showForgot">
      <a [routerLink]="['/account/forgot-password']">FORGOT PASSWORD OR USERNAME?</a>
    </p>
    <p><strong>Contact Us:</strong> <br />
      <a href="mailto:merchandise@tlc-live.co.nz">merchandise@tlc-live.co.nz</a><br />
      call us 0508 697 392
    </p>
  </mat-card>
</div>