
export const ADDRESS_DETAILS = (disabled = false) => ({
  id: 'ADDRESS_DETAILS',
  template: [
    {
      wrappers: ['panel'],
      templateOptions: {
        label: 'Formly'
      },
      fieldGroup: [
        { template: '<h2>Confirm delivery address</h2>' },
        { key: 'deliveryFirstName', type: 'input', templateOptions: { label: 'First Name', placeholder: 'First Name', required: true } },
        { key: 'deliveryLastName', type: 'input', templateOptions: { label: 'Last Name', placeholder: 'Last Name', required: true } },
        { key: 'deliveryCompany', type: 'input', templateOptions: { label: 'Company', placeholder: 'Company', required: true } },
        { key: 'deliveryAddress1', type: 'input', templateOptions: { label: 'Address 1', placeholder: 'Address 1', required: true } },
        { key: 'deliveryAddress2', type: 'input', templateOptions: { label: 'Address 2', placeholder: 'Address 2' } },
        { key: 'deliveryCity', type: 'input', templateOptions: { label: 'City', placeholder: 'City', required: true } },
        { key: 'deliveryPostCode', type: 'input', templateOptions: { label: 'PostCode', placeholder: 'PostCode', required: true } },
        { key: 'rememberAddress', type: 'checkbox', templateOptions: { label: 'Remember this address', } },
        { template: '<h2>Confirm details</h2>' },
        { key: 'customerOrderNo', type: 'input', templateOptions: { label: 'PO number', placeholder: 'PO#/Reason for Purchase', required: true } },
        { key: 'estimatedDeliveryDate', type: 'datepicker', templateOptions: { label: 'Date Required', placeholder: 'Date Required', required: false } },
      ]
    }
  ]
});
