import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services';
import { FormlyService } from 'src/app/ui-form/formly-service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public form = new UntypedFormGroup({});
  isSaving = false;
  submitStatus: number = 0;
  returnUrl: string = '/';
  error = '';
  public fields: FormlyFieldConfig[] = [
    ...this.formlyService.getFormById('RESET_PASSWORD_FORM'),
  ];

  public model: any = { token: '' };

  constructor(
    private formlyService: FormlyService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(map(params => this.model.token = params.get('token')))
      .subscribe()
  }

  submit() {
    if (this.isSaving) return;
    this.isSaving = true;

    const resetRequest = {
      token: this.model.token,
      password: this.model.password?.password,
      confirmPassword: this.model.password?.confirmPassword
    }
    this.authenticationService.resetPassword(resetRequest)
      .subscribe(r => {
        this.isSaving = false;
        this.submitStatus = 1;
      },
        err => {
          console.error(err)
          this.isSaving = false;
          this.submitStatus = 2;
        })
  }

}
