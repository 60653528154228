import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account, ChangePassword, LoginAccount, ViewAsAccount } from 'src/app/auth/models';
import { HttpWrapperService } from './http-wrapper.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentAccountSubject: BehaviorSubject<Account> = new BehaviorSubject(null);
  public currentAccount$: Observable<Account> = this.currentAccountSubject.asObservable()
    .pipe(
      map(c => c ? Object.assign({}, c) : null)
    );

  constructor(private http: HttpWrapperService) { }

  public get currentAccountValue(): Account {
    return this.currentAccountSubject.value;
  }

  login(loginAccount: LoginAccount): Observable<Account> {
    return this.http.post(`accounts/authenticate`, loginAccount)
      .pipe(map((account: Account) => {
        // store account details and jwt token in local storage to keep account logged in between page refreshes
        this.currentAccountSubject.next(account);
        if (account.registered)
          this.startRefreshTokenTimer();
        return account;
      }));
  }

  viewAs(viewAsAccount: ViewAsAccount): Observable<Account> {
    return this.http.post(`accounts/viewAs`, viewAsAccount)
      .pipe(map((viewAsAccount: Account) => {
        // store viewAsAccount details and jwt token in local storage to keep viewAsAccount logged in between page refreshes
        if (viewAsAccount) {
          this.stopRefreshTokenTimer()
          this.currentAccountSubject.next(null);
          this.currentAccountSubject.next(viewAsAccount);
          if (viewAsAccount.registered)
            this.startRefreshTokenTimer();
          return viewAsAccount;
        }
      }));
  }

  logout() {
    // remove account from local storage to log account out
    if (!this.currentAccountSubject.value)
      return;
    this.http.post(`accounts/revoke-token`, {}, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer()
    this.currentAccountSubject.next(null);
  }

  refreshToken() {
    return this.http.post(`accounts/refresh-token`, {}, { withCredentials: true })
      .pipe(map(account => {
        this.currentAccountSubject.next(account);
        if (account) {
          this.startRefreshTokenTimer();
        }
        return account;
      }));
  }

  changePassword(model: ChangePassword): Observable<boolean> {
    return this.http.post(`accounts/changePassword`, model);
  }

  forgotPassword(model) {
    return this.http.post(`accounts/forgot-Password`, model);
  }

  resetPassword(model) {
    return this.http.post(`accounts/reset-Password`, model);
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.currentAccountSubject.value.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}