import { Component, OnDestroy } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services';
import { FormlyService } from 'src/app/ui-form/formly-service';
import { LoginAccount } from '../../models/loginAccount';

@Component({
  styleUrls: ['./login.component.css'],
  selector: 'app-login',
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnDestroy {
  private subscription: Subscription;
  public form = new UntypedFormGroup({});

  isSubmitting = false;
  returnUrl: string = '/home';
  error = '';

  public fields: FormlyFieldConfig[] = [
    ...this.formlyService.getFormById('LOGIN_FORM')
  ];
  public model: any = { username: '', password: '', registering: false };
  options = {
    formState: {
      mainModel: this.model,
    }
  };

  constructor(
    private formlyService: FormlyService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.logout()
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  submit(formDirective: FormGroupDirective) {
    if (this.isSubmitting) return;
    this.isSubmitting = true;
    const newLogin: LoginAccount = <LoginAccount>{
      username: this.model.username,
      password: this.model.password,
      newPassword: this.model.newPassword?.newPassword,
      confirmPassword: this.model.newPassword?.confirmPassword
    }
    this.subscription = this.authenticationService
      .login(newLogin)
      .subscribe(
        account => {
          this.isSubmitting = false;
          if (account.registered) {
            this.router.navigate([this.returnUrl]);
          }
          else {
            this.model.registering = true;
            formDirective.resetForm(this.model);
          }
          return account;
        },
        (error) => {
          console.error(error)
          this.error = error;
          this.isSubmitting = false;
        }
      );
  }
}
