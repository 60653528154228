import { Injectable } from '@angular/core';
import { ContactFormModel } from './../models/';
import { HttpWrapperService } from './http-wrapper.service';

const path = 'email';

@Injectable()
export class EmailService {
  constructor(private HttpWrapperService: HttpWrapperService) {
  }

  contactForm(contactForm: ContactFormModel) {
    return this.HttpWrapperService.post(`${path}/contactForm`, contactForm);
  }
}
