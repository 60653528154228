import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CategoryModel } from 'src/app/core/models';
import { CategoryService } from 'src/app/core/services/category.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {
  @Input() parentId: number;
  @Input() isExpanded: boolean = false;
  public categories: Observable<CategoryModel[]>;

  constructor(public categoryService: CategoryService, private router: Router) { }

  ngOnInit() {
    this.categories = this.categoryService.categories;
  }

  async showCategory(category: CategoryModel) {
    this.router.navigate(['/products', category.name]);
  }
}
