import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomerDetailsModel } from 'src/app/core/models';
import { AuthenticationService } from './authentication.service';
import { HttpWrapperService } from './http-wrapper.service';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  private _details$ = new BehaviorSubject<CustomerDetailsModel>(null);
  public customerDetails$: Observable<CustomerDetailsModel> = this._details$.asObservable()
    .pipe(
      map(c => Object.assign({}, c))
    );

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private HttpWrapperService: HttpWrapperService) {
  }

  refresh(force: boolean = false) {
    if (force || !this._details$.getValue()) {
      this.HttpWrapperService.get(`customers/getDetails`)
        .pipe(
          catchError(error => {
            if (error.status === 401) {
              this.authenticationService.logout();
              this.router.navigate(['/']);
            }
            return throwError(error);
          })
        )
        .subscribe(details => {
          this._details$.next(details);
        });
    }
  }

  submit(customer: CustomerDetailsModel) {
    this._details$.next(customer)
    return this.HttpWrapperService.post(`customers/updateDetails`, customer);
  }
}
