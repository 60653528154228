<div *ngIf="products$ | async as products" gdGap="10px" gdRows grid-auto-rows="1fr">
  <mat-card *ngFor="let product of products" (click)="showProduct(product)" class="mat-elevation-z3">
    <h3>Feature Product</h3>
    <div class="image">
      <img [src]="getProductImage(product)" />
    </div>
    <div class="detail">
      <p>{{product.price| currency}}</p>
      <p>{{product.name}}</p>
      <button mat-flat-button color="primary" (click)="showProduct(product)">
        View Product
      </button>
    </div>
  </mat-card>
</div>