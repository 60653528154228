import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { Account, Role } from 'src/app/auth/models';
import { CustomerDetailsModel } from 'src/app/core/models';
import { AuthenticationService, CartService, CategoryService, CustomerService, ProductService, Tenant, TenantService } from 'src/app/core/services';
import { ProductModel } from './../../core/models/product';

@Component({
  selector: 'app-responsive-nav',
  templateUrl: './responsive-nav.component.html',
  styleUrls: ['./responsive-nav.component.css'],
})
export class ResponsiveNavComponent implements OnInit, OnDestroy {
  hidePoints = false;
  isAltusUser = false;
  isAdmin = false;

  private subscription: Subscription;
  customer$: Observable<CustomerDetailsModel> = this.customerService.customerDetails$;

  isOpened: boolean = true;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => {
        let isHandset = result.matches;
        this.isOpened = !isHandset;
        return isHandset;
      }),
      shareReplay()
    );

  @ViewChild('menuTrigger') trigger: MatMenuTrigger;

  currentAccount$: Observable<Account>;
  cartCount$: Observable<number>;
  filteredOptions: Observable<ProductModel[]>;

  ctrlSearch = new UntypedFormControl('', Validators.required);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private customerService: CustomerService,
    private categoryService: CategoryService,
    private productService: ProductService,
    private tenantService: TenantService,
    private router: Router,
    private cartService: CartService,
    private authenticationService: AuthenticationService
  ) {

  }

  ngOnInit() {
    this.isAltusUser = this.tenantService.getTenant() == Tenant.Altus
    this.currentAccount$ = this.authenticationService.currentAccount$
      .pipe(map(a => {
        this.isAdmin = a?.role === Role.Admin;
        return a;
      }));
    this.cartCount$ = this.cartService.cartItemCount$;

    this.subscription = this.categoryService.terminalClicked$
      .pipe(
        map(b => { if (b) this.menuOpened(!b); })
      )
      .subscribe();

    this.filteredOptions = this.ctrlSearch.valueChanges.pipe(
      startWith(''),
      switchMap(value => this._filter(value))
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  menuOpened(status: boolean) {
    this.isOpened = status;
  }

  toggleMenu() {
    this.isOpened = !this.isOpened;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }

  gotoPage(page: string) {
    this.router.navigate(['/' + page]);
  }

  isHomeRoute() {
    return this.router.url.includes("/home");
  }

  showProductName(product: ProductModel): string {
    return product && product.name ? product.name : '';
  }

  private _filter(value: string | ProductModel) {
    if (typeof value === 'string') {
      if (value === '') return of([]);
      const filterValue = value.toLowerCase();
      return this.productService.search(filterValue);
    }
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.navigate(['/search/product/' + value.id])
      return of([])
    }
  }
}
