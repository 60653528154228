import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductModel } from 'src/app/core/models';
import { ProductService } from 'src/app/core/services';

@Component({
  selector: 'app-featured-products',
  templateUrl: './featured-products.component.html',
  styleUrls: ['./featured-products.component.scss']
})
export class FeaturedProductsComponent implements OnInit {
  products$: Observable<ProductModel[]>;
  category: string;

  constructor(
    private productService: ProductService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.products$ = this.productService.getFeatured();
  }

  getProductImage(product: ProductModel): string {
    let link = product.images.length > 0 ? product.images[0].link : product.productOptions[0].image?.link ?? '';
    return link.replace('http://', 'https://').replace('//cin7.com', '//go.cin7.com');
  }

  showProduct(product: ProductModel) {
    this.router.navigate(['/catalogues/product', product.id]);
  }

}
