import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductModel } from '../models/product';
import { HttpWrapperService } from './http-wrapper.service';

const path = 'product';

@Injectable()
export class ProductService {
  constructor(private HttpWrapperService: HttpWrapperService) { }

  get(categoryId: number): Observable<ProductModel[]> {
    return this.HttpWrapperService.get(`${path}/${categoryId}`).pipe(
      map(this.mapProductArray.bind(this))
    );
  }

  getFeatured(): Observable<ProductModel[]> {
    return this.HttpWrapperService.get(`${path}/getFeatured`).pipe(
      map(this.mapProductArray.bind(this))
    );
  }

  search(searchText: string): Observable<ProductModel[]> {
    return this.HttpWrapperService.get(`${path}/search/${searchText}`).pipe(
      map(this.mapProductArray.bind(this))
    );
  }

  getProduct(productId: string): Observable<ProductModel> {
    return this.HttpWrapperService.get(`${path}/getById/${productId}`).pipe(
      map(this.mapProduct.bind(this))
    );
  }

  private mapProductArray(products:ProductModel[]) {
    products.map(this.mapProduct.bind(this))
    return products;
  }

  private mapProduct(product:ProductModel) {
    product.price = product?.productOptions[0]?.price
    product._sortByName = this.addCustomSorting(product.name);
    product.name = this.formatName(product.name);
    return product;
  }

  private addCustomSorting(name:string):string {
    if (name.startsWith('[')) return name;
    return '[a]' + name
  }

  private formatName(name: string): string {
    return name.replace(/\[.*?\]/g, '').trim();
  }
}
