import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from '../auth/models';
import { AuthGuard } from '../auth/_helpers';
import { ChangePasswordComponent } from './../auth/components/change-password/change-password.component';
import { LoginComponent } from './../auth/components/login/login.component';
import { AnonComponent } from './anon/anon.component';
import { AuthComponent } from './auth/auth.component';

const routes: Routes = [
  {
    path: '',
    component: AnonComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'account', loadChildren: async () => (await import('../auth/auth.module')).AuthModule },
      { path: 'contactUs', loadChildren: async () => (await import('../anon-contact-form/anon-contact-form.module')).AnonContactFormModule }
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: AuthComponent,
    children: [
      { path: 'home', loadChildren: async () => (await import('../home/home.module')).HomeModule },
      { path: 'search', loadChildren: async () => (await import('../product/product.module')).ProductModule },
      { path: 'catalogues', loadChildren: async () => (await import('../product/product.module')).ProductModule },
      { path: 'details', loadChildren: async () => (await import('../customer-details/customer-details.module')).CustomerDetailsModule },
      { path: 'history', loadChildren: async () => (await import('../history/history.module')).HistoryModule },
      { path: 'changePassword', component: ChangePasswordComponent },
      { path: 'contact', loadChildren: async () => (await import('../contact-form/contact-form.module')).ContactFormModule },
      { path: 'cart', loadChildren: async () => (await import('../cart/cart.module')).CartModule },
      { path: 'checkout', loadChildren: async () => (await import('../checkout/checkout.module')).CheckoutModule },
      { path: 'viewas', canActivate: [AuthGuard], data: { roles: [Role.Admin] }, loadChildren: async () => (await import('../view-as/view-as.module')).ViewAsModule }
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SiteLayoutRoutingModule {
  static components = [AnonComponent, AuthComponent];
}
