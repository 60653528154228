<mat-card>
    <alert></alert>
    <h1>Reset Password</h1>
    <form [formGroup]="form" (ngSubmit)="submit()" class="form" autocomplete="false" *ngIf="submitStatus==0">
        <formly-form [form]="form" [fields]="fields" [model]="model">
            <div class="form-element">
                <button mat-flat-button color="primary" type="submit" class="button" [appShowSpinner]="isSaving"
                    [disabled]="!form.valid">Reset Password</button>
            </div>
        </formly-form>
    </form>
    <p *ngIf="submitStatus==1">Your password has now been reset. Please proceed to the <a [routerLink]="['/']">login
            page</a>.</p>
    <p *ngIf="submitStatus==2">Password reset validation failed, if the token has expired you can get a new one at the
        <a [routerLink]="['/account/forgot-password']">forgot password</a> page.
    </p>
</mat-card>
<app-footer></app-footer>