import { ConfigOption } from "@ngx-formly/core";
import { maxlengthValidationMessage, maxValidationMessage, minlengthValidationMessage, minValidationMessage } from './helpers/validations-messages';
import { maximumMoneyValidation } from './helpers/validators';
import { TextareaAutoResizeComponent } from './templates/textarea-autoresize';
import { ErrorWrapperComponent } from './wrappers/error.component';
import { PanelWrapperComponent } from './wrappers/panel-wrapper.component';

export const config: ConfigOption = {
  validationMessages: [
    { name: 'required', message: 'This field is required' },
    { name: 'minlength', message: minlengthValidationMessage },
    { name: 'maxlength', message: maxlengthValidationMessage },
    { name: 'min', message: minValidationMessage },
    { name: 'max', message: maxValidationMessage },
    { name: 'maximumMoneyValidation', message: 'should be 100' }
  ],
  wrappers: [
    { name: 'panel', component: PanelWrapperComponent },
    { name: 'error-on-top', component: ErrorWrapperComponent }
  ],
  types: [
    //  { name: 'repeat', component: RepeatSectionComponent },
    { name: 'textarea-auto-resize', component: TextareaAutoResizeComponent, wrappers: ['form-field'] }
  ],
  validators: [
    { name: 'maximumMoneyValidation', validation: maximumMoneyValidation }
  ]
};
