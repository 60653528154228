<ng-container *ngIf="(currentAccount$ | async) as currentAccount">
  <mat-toolbar color="primary">
    <mat-toolbar-row class="container">
      <button mat-button [routerLink]="['/home']" class="logo">
        <img [src]="currentAccount.company.logo" />
      </button>
      <span class="spacer"></span>
      <span style="margin-right: 40px;font-size: 14px;"
        *ngIf="isAltusUser && !hidePoints && (customer$ | async) as customer">
        Store Credit Available: {{customer.points?.redeemable | currency}}
      </span>
      <mat-search-bar [formControl]="ctrlSearch" [matAutocomplete]="auto" (onOpen)="hidePoints=true"
        (onClose)="hidePoints=false">
      </mat-search-bar>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="showProductName" [panelWidth]="'300px'">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
      <div>
        <button mat-button [routerLink]="['/cart']">
          <div *ngIf="cartCount$ | async as count;else noItems">
            <mat-icon class="icon pointer material-icons-outlined" [matBadge]="count+''" matBadgeColor="warn">
              shopping_cart</mat-icon>
          </div>
        </button>
        <ng-template #noItems>
          <mat-icon class="icon pointer"> shopping_cart</mat-icon>
        </ng-template>
      </div>
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleMenu()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="personMenu" *ngIf="!(isHandset$ | async)">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #personMenu="matMenu">
        <button mat-menu-item color="primary" (click)="gotoPage('details')">
          <span>My Details</span>
        </button>
        <button mat-menu-item color="primary" (click)="gotoPage('history')">
          <span>Order History</span>
        </button>
        <button mat-menu-item color="primary" (click)="gotoPage('contact')">
          <span>Contact Us</span>
        </button>
        <button mat-menu-item color="primary" (click)="gotoPage('changePassword')">
          <span>Change Password</span>
        </button>
        <button mat-menu-item color="primary" (click)="logout()">
          <span>Logout</span>
        </button>
        <button mat-menu-item color="primary" (click)="gotoPage('viewas')" *ngIf="isAdmin">
          <span>View As</span>
        </button>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-toolbar class="welcome" *ngIf="isHomeRoute() && !(isHandset$ | async)">
    <mat-toolbar-row class="container banner">
    </mat-toolbar-row>
  </mat-toolbar>
  <app-loader></app-loader>
  <mat-sidenav-container class="sideNav-container">
    <mat-sidenav #drawer class="sideNav" [opened]="isOpened" *ngIf="(isHandset$ | async) as isHandset"
      [attr.role]="'dialog'" [mode]="'over'" (closed)="menuOpened(false)" (opened)="menuOpened(true)">
      <app-person-intro *ngIf="isAltusUser"></app-person-intro>

      <mat-nav-list>
        <a mat-list-item [routerLink]="['/home']" (click)="drawer.toggle()">Home</a>
        <app-altus-category-menu [parentId]="0" [isExpanded]="true"> </app-altus-category-menu>
        <a mat-list-item [routerLink]="['/details']" (click)="drawer.toggle()">My Details</a>
        <a mat-list-item [routerLink]="['/contact']" (click)="drawer.toggle()">Contact Us</a>
        <a mat-list-item [routerLink]="['/history']" (click)="drawer.toggle()">Order History</a>
        <a mat-list-item (click)="logout()">Logout</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav class="sideNav" [opened]="true" *ngIf="!(isHandset$ | async)" [attr.role]="'navigation'"
      [mode]="'side'">
      <app-altus-category-menu [parentId]="0" [isExpanded]="true"> </app-altus-category-menu>
    </mat-sidenav>
    <mat-sidenav-content style="padding-right: 15px;">

      <alert></alert>
      <router-outlet></router-outlet>

    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
