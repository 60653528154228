import { Component, OnInit } from '@angular/core';
import { CategoryService, Tenant, TenantService } from 'src/app/core/services';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  theme: string = 'store-theme';

  constructor(
    private tenantService: TenantService,
    private categoryService: CategoryService
  ) { }

  ngOnInit(): void {
    this.categoryService.initialize();
    let tenant = this.tenantService.getTenant();
    if (tenant === Tenant.Altus) {
      this.theme = 'altus-theme';
    }
  }
}
