import { Component, OnInit } from '@angular/core';
import { CategoryService, TenantService } from 'src/app/core/services';
import { Tenant } from './../../core/services/tenant.service';

@Component({
  selector: 'app-anon',
  templateUrl: './anon.component.html',
  styleUrls: ['./anon.component.css']
})
export class AnonComponent implements OnInit {
  imageIndex: number = 0;
  logo: string = '/assets/images/TLC Live Logo Landscape.png';
  theme: string = 'store-theme';
  images = [
    { id: 1, title: 'image', url: '/assets/images/login/The Store Home Buttoning Shirt.png' },
    { id: 2, title: 'image', url: '/assets/images/login/The Store Home2  Notebook.png' },
    { id: 3, title: 'image', url: '/assets/images/login/The Store Home3 Bag.png' },
    { id: 4, title: 'image', url: '/assets/images/login/The Store Home4 backpack.png' },
    { id: 5, title: 'image', url: '/assets/images/login/The Store Home5 grass.png' },
    { id: 6, title: 'image', url: '/assets/images/login/The Store Home6 Singlet.png' },
  ];

  constructor(
    private tenantService: TenantService,
    private categoryService: CategoryService
  ) { }

  ngOnInit(): void {
    this.categoryService.clear();
    let tenant = this.tenantService.getTenant();
    if (tenant === Tenant.Altus) {
      this.theme = 'altus-theme';
      this.logo = '/assets/images/altus-logo.png';
      this.images = [
        { id: 1, title: 'image', url: '/assets/images/login/Altus.png' }
      ];
    }
    this.imageIndex = Math.floor(Math.random() * this.images.length);
  }
}
