<mat-card>
    <alert></alert>
    <form [formGroup]="form" (ngSubmit)="submit(formDirective)" #formDirective="ngForm" class="form"
        autocomplete="false">
        <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options">
            <div class="form-element">
                <button mat-flat-button color="primary" type="submit" class="button" [appShowSpinner]="isSubmitting"
                    [disabled]="!form.valid">Log In</button>
            </div>
        </formly-form>
    </form>
</mat-card>
<app-footer [showForgot]="true"></app-footer>