<mat-toolbar>
  <mat-toolbar-row>
    <ng-container class="breadcrumb" *ngIf="breadcrumb">
      <ng-container *ngFor="let breadcrumb of (breadcrumbs$ | async); let last=last">
        <a (click)="clicked(breadcrumb)">{{breadcrumb.name}}</a>
        <span class="separator" *ngIf="!last">&raquo;</span>
      </ng-container>
    </ng-container>
    <h1 *ngIf="title !== 'Catalogues'">{{title}}</h1>
  </mat-toolbar-row>
</mat-toolbar>
