import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { Tenant, TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private default_title: string = 'The Store'

  constructor(private router: Router,
    private activeRoute: ActivatedRoute,
    private tenantService: TenantService,
    private title: Title) { }

  public init() {
    this.default_title = this.tenantService.getTenant() == Tenant.Altus ? 'Altus Store' : 'The Store';
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activeRoute),
      map(route => route.firstChild),
      switchMap(route => route.data),
      map((data) => {
        return data && data.title ? `${data.title} • ${this.default_title}` : this.default_title;
      })
    ).subscribe((current_title) => this.title.setTitle(current_title));
  }
}
