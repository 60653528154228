import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SalesOrderModel } from './../models/sales.order';
import { SalesOrderResponseModel } from './../models/sales.order.response';
import { HttpWrapperService } from './http-wrapper.service';

const path = 'salesOrder';

@Injectable()
export class SalesOrderService {
  constructor(private HttpWrapperService: HttpWrapperService) { }

  getSubmitted(): Observable<SalesOrderModel[]> {
    return this.HttpWrapperService.get(`${path}/getSubmitted`);
  }

  getCart(): Observable<SalesOrderModel> {
    return this.HttpWrapperService.get(`${path}/getCart`);
  }

  saveCart(salesOrderModel: SalesOrderModel): Observable<SalesOrderResponseModel> {
    return this.HttpWrapperService.put(`${path}/saveCart`, salesOrderModel);
  }

  submitCart(salesOrderModel: SalesOrderModel): Observable<SalesOrderModel> {
    return this.HttpWrapperService.put(`${path}/submitCart`, salesOrderModel);
  }
}
