import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthenticationService } from '../core/services/authentication.service';
import { LayoutModule } from './../layout/layout.module';
import { SharedModule } from './../shared/shared.module';
import { UiFormModule } from './../ui-form/formly.module';
import { AuthRoutingModule } from './auth-routing.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { appInitializer, JwtInterceptor } from './_helpers';

@NgModule({
  imports: [
    AuthRoutingModule,
    UiFormModule,
    LayoutModule,
    SharedModule
  ],
  declarations: [AuthRoutingModule.components, ChangePasswordComponent, ForgotPasswordComponent, ResetPasswordComponent],
  exports: [AuthRoutingModule.components],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ]
})
export class AuthModule { }
