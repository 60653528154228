import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { SiteLayoutRoutingModule } from './site-layout-routing.module';

@NgModule({
  imports: [
    SharedModule,
    SiteLayoutRoutingModule,
  ],
  declarations: [SiteLayoutRoutingModule.components],
  providers: [],
})
export class SiteLayoutModule {
  // https://medium.com/angular-in-depth/angular-routing-reusing-common-layout-for-pages-from-different-modules-440a23f86b57
}
