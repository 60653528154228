export const CONTACT_FORM = (disabled = false) => ({
  id: 'CONTACT_FORM',
  template: [
    {
      wrappers: ['panel'],
      templateOptions: {
        label: 'Formly'
      },
      fieldGroup: [
        { key: 'message', type: 'textarea', templateOptions: { label: 'Message', placeholder: 'Message', required: true, autosize: false, rows: 5 } }
      ]
    }
  ]
});

export const CONTACT_FORM_ANON = (disabled = false) => ({
  id: 'CONTACT_FORM_ANON',
  template: [
    {
      wrappers: ['panel'],
      templateOptions: {
        label: 'Formly'
      },
      fieldGroup: [
        { key: 'firstName', type: 'input', templateOptions: { label: 'First Name', placeholder: 'First Name', required: true } },
        { key: 'surname', type: 'input', templateOptions: { label: 'Surname', placeholder: 'Surname', required: true } },
        { key: 'company', type: 'input', templateOptions: { label: 'Company Name', placeholder: 'Company Name', required: true } },
        { key: 'email', type: 'input', templateOptions: { label: 'Email', placeholder: 'Email', required: true } },
        { key: 'mobile', type: 'input', templateOptions: { label: 'Mobile', placeholder: 'Mobile' } },
        { key: 'phone', type: 'input', templateOptions: { label: 'Phone', placeholder: 'Phone', required: true } },
        { key: 'message', type: 'textarea', templateOptions: { label: 'Message', placeholder: 'Message', required: true, autosize: false, rows: 5 } }
      ]
    }
  ]
});
