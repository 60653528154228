import { HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  constructor() { }

  getTenantForHostname(hostname: string): Tenant {
    let host = hostname.split(".");
    if (host.length > 1 && host[0] === 'www') {
      return this.getTenantForHost(host[1]);
    }
    else {
      return this.getTenantForHost(host[0]);
    }
  }

  getTenantForHost(host: string): Tenant {
    return this.getTenantForString(host);
  }

  getTenantForString(s: string) {
    for (const e in Tenant) {
      if (s.toLowerCase().startsWith(e.toLowerCase())) {
        return Tenant[e] as Tenant;
      }
    }
    if (s.toLowerCase().startsWith('nulook')) {
      return Tenant.Altus;
    }
    return Tenant.TheStore;
  }

  getTenant(): Tenant {
    return this.getTenantForHostname(location.hostname);
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    const tenant = this.getTenant();
    if (tenant)
      return headers.append("X-Tenant-ID", tenant);
  }
}

export enum Tenant {
  Altus = "Altus",
  TheStore = "The Store"
}