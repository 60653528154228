import { ADDRESS_DETAILS } from './address-details.form';
import { CHANGE_PASSWORD } from './change-password.form';
import { CONTACT_FORM, CONTACT_FORM_ANON } from './contact.form';
import { CUSTOMER_DETAILS } from './customer-details.form';
import { FORGOT_PASSWORD_FORM } from './forgot-password.form';
import { LOGIN_FORM } from './login.form';
import { RESET_PASSWORD_FORM } from './reset-password.form';
import { VIEW_AS_FORM } from './view-as.form';

export const FORMS_VALUES = (disabled = false) => ({
  [LOGIN_FORM().id]: LOGIN_FORM(disabled).template,
  [VIEW_AS_FORM().id]: VIEW_AS_FORM(disabled).template,
  [FORGOT_PASSWORD_FORM().id]: FORGOT_PASSWORD_FORM(disabled).template,
  [RESET_PASSWORD_FORM().id]: RESET_PASSWORD_FORM(disabled).template,
  [ADDRESS_DETAILS().id]: ADDRESS_DETAILS(disabled).template,
  [CUSTOMER_DETAILS().id]: CUSTOMER_DETAILS(disabled).template,
  [CONTACT_FORM().id]: CONTACT_FORM(disabled).template,
  [CONTACT_FORM_ANON().id]: CONTACT_FORM_ANON(disabled).template,
  [CHANGE_PASSWORD().id]: CHANGE_PASSWORD(disabled).template,
});

