import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from './../auth/auth.module';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-only-loaded-once.guard';
import { LoadingInterceptorService } from './interceptors/loading.interceptor';
import { AddressService, AuthenticationService, CartService, CategoryService, EmailService, HttpWrapperService, NavigationHistoryService, ProductOptionService, ProductService, SalesOrderService, TenantService, TitleService } from './services';
import { TenantModule } from './tenant/tenant.module';
import { CreditNoteService } from './services/credit-note.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AuthModule,
    TenantModule
  ],
  exports: [
    //  AuthModule
  ],
  declarations: [
  ],
  providers: [
    HttpWrapperService,
    AuthenticationService,
    EmailService,

    AddressService,
    CartService,
    ProductService,
    ProductOptionService,
    NavigationHistoryService,
    CategoryService,
    TenantService,
    SalesOrderService,
    CreditNoteService,
    TitleService,

    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptorService, multi: true }
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
