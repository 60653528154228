
export const RESET_PASSWORD_FORM = (disabled = false) => ({
  id: 'RESET_PASSWORD_FORM',
  template: [
    {
      wrappers: ['panel'],
      templateOptions: {
        label: 'Formly'
      },
      fieldGroup: [
        {
          key: 'password',
          hideExpression: (model: any, formState: any) => {
            return formState.mainModel && !formState.mainModel.registering;
          },
          validators: {
            fieldMatch: {
              expression: (control) => {
                const value = control.value;

                return value?.confirmPassword === value?.password
                  // avoid displaying the message error when values are empty
                  || (!value.confirmPassword || !value.password);
              },
              message: 'Password Not Matching',
              errorPath: 'confirmPassword',
            },
          },
          fieldGroup: [
            {
              key: 'password',
              type: 'input',
              templateOptions: {
                type: 'password',
                label: 'New Password',
                placeholder: 'New Password',
                required: true
              }
            },
            {
              key: 'confirmPassword',
              type: 'input',
              templateOptions: {
                type: 'password',
                label: 'Confirm Password',
                placeholder: 'Confirm Password',
                required: true
              }
            }

          ]
        }
      ],
    }
  ]
});
