import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { CheckoutModel, LineItem, SalesOrderModel } from 'src/app/core/models';
import { AuthenticationService } from './authentication.service';
import { CustomerService } from './customer.service';
import { SalesOrderService } from './sales.order.service';
@Injectable({
  providedIn: 'root',
})
export class CartService {
  private _cart$ = new ReplaySubject<SalesOrderModel>(1);
  public cart$: Observable<SalesOrderModel> = this._cart$.asObservable()
    .pipe(
      map(c => c ? Object.assign({}, c) : null)
    );
  private cartItemCountSubject$ = new BehaviorSubject<number>(0);
  public cartItemCount$ = this.cartItemCountSubject$.asObservable();
  private cart: SalesOrderModel;

  constructor(
    private salesOrderService: SalesOrderService,
    private authenticationService: AuthenticationService,
    private customerService: CustomerService,
    private toastr: ToastrService) {
    this.authenticationService.currentAccount$
      .pipe(
        switchMap(account => account ? this.salesOrderService.getCart() : of(null)),
        map(order => this.setupCart(order)),
        shareReplay(1)
      )
      .subscribe();
  }

  addToCart(cartItems: LineItem[]) {
    cartItems.forEach(newItem => {
      let itemIndex = this.findCartItemIndex(newItem);
      if (itemIndex >= 0) {
        this.cart.lineItems[itemIndex].qty += newItem.qty;
      }
      else {
        // TODO: investigate option 1 not being updated in Cin7
        //        newItem.option1 = newItem.option1.toUpperCase()
        this.cart.lineItems.push(newItem);
      }
    })
    this.saveCart();
  }

  deleteItem(item: LineItem) {
    if (this.cart.lineItems.length === 1) {
      this.toastr.info('Your cart must contain at least 1 item.');
      item._updating = false;
      return;
    }
    let itemIndex = this.findCartItemIndex(item);
    if (itemIndex >= 0) {
      this.cart.lineItems.splice(itemIndex, 1);
      this.saveCart();
    }
  }

  changeQuantity(item: LineItem, newQuantity: number) {
    if (isNaN(newQuantity) || !newQuantity)
      return;
    let itemIndex = this.findCartItemIndex(item);
    let size = this.getItemSize(this.cart.lineItems[itemIndex].option3);
    if (itemIndex >= 0) {

      this.cart.lineItems[itemIndex] = { ...this.cart.lineItems[itemIndex], qty: newQuantity, _updating: true, option3: size ? `${newQuantity} x ${size}` : '' }
      console.log(this.cart.lineItems[itemIndex].option1)
      this.cart.lineItems[itemIndex].option1=this.cart.lineItems[itemIndex].option1.toUpperCase()
      this.saveCart();
    }
  }

  toggleStoreCredit() {
    this.cart.useStoreCredit = !this.cart.useStoreCredit;
  }

  updateContactDetails(customer: CheckoutModel) {
    this.cart.firstName = customer.firstName;
    this.cart.lastName = customer.lastName;
    this.cart.company = customer.company;
    this.cart.deliveryFirstName = customer.deliveryFirstName;
    this.cart.deliveryLastName = customer.deliveryLastName;
    this.cart.deliveryCompany = customer.deliveryCompany;
    this.cart.deliveryAddress1 = customer.deliveryAddress1;
    this.cart.deliveryAddress2 = customer.deliveryAddress2;
    this.cart.deliveryCity = customer.deliveryCity;
    this.cart.deliveryPostalCode = customer.deliveryPostCode;
    this.cart.customerOrderNo = customer.customerOrderNo;
    this.cart.estimatedDeliveryDate = customer.estimatedDeliveryDate;
    this.cart.rememberAddress = customer.rememberAddress;
  }

  submitCart() {
    return this.salesOrderService
      .submitCart(this.cart)
      .pipe(map((order) => this.setupCart(order)));
  }

  itemCompare(item1: LineItem, code: string, option1: string, option2: string, size: string): boolean {
    if (item1.code == code) {
      let found: boolean = true;
      if (option1 && item1.option1 !== option1) found = false;
      if (option2 && item1.option2 !== option2) found = false;
      if (size && !item1.option3.endsWith(` x ${size}`)) found = false;
      return found;
    }
    else {
      return false;
    }
  }

  private findCartItemIndex(newItem: LineItem): number {
    let size = this.getItemSize(newItem.option3);
    let index = this.cart.lineItems.findIndex(cartItem => this.itemCompare(cartItem, newItem.code, newItem.option1, newItem.option2, size));
    return index;
  }

  private getItemSize(sizeString: string): string {
    let sizeArray = sizeString.split(' x ');
    return sizeArray[sizeArray.length - 1].trim();
  }

  private updateTotals() {
    let count: number = 0;
    let total: number = 0;
    if (this.cart) {
      this.cart.lineItems.forEach((item) => {
        count += item.qty;
        total += item.qty * item.unitPrice;
      });
      this.cart.productTotal = total;
      this.cart.total = total * 1.15;
    }
    this.cartItemCountSubject$.next(count);
    this._cart$.next(Object.assign({}, this.cart));
  }

  private setupCart(order: SalesOrderModel): SalesOrderModel {
    let newOrder = Object.assign({}, order);
    this._cart$.next(newOrder);
    this.cart = newOrder;
    if (Object.keys(newOrder).length > 0) {
      this.cart.useStoreCredit = true;
      this.updateTotals();
    }
    if (order) {
      this.customerService.refresh(true);
    }
    return order;
  }

  private saveCart() {
    this.salesOrderService.saveCart(this.cart).subscribe(response => {
      this.cart.lineItems.forEach((item) => (item._updating = false));
      if (!this.cart.reference) {
        this.cart.reference = response.code;
        this.cart.id = response.id;
      }
      this.setupCart(this.cart);
    });
  }
}
