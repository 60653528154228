import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { LayoutModule } from './../layout/layout.module';
import { AlertComponent } from './alert/alert.component';
import { AltusCategoryItemComponent } from './altus-category-item/altus-category-item.component';
import { AltusCategoryMenuComponent } from './altus-category-menu/altus-category-menu.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryMenuComponent } from './category-menu/category-menu.component';
import { ColourSelectorItemComponent } from './colour-selector-item/colour-selector-item.component';
import { ColourSelectorComponent } from './colour-selector/colour-selector.component';
import { CssCarouselComponent } from './css-carousel/css-carousel.component';
import { ShowSpinnerDirective } from './directives/show-spinner.directive';
import { FeaturedProductsComponent } from './featured-products/featured-products.component';
import { FooterComponent } from './footer/footer.component';
import { IncrementInputComponent } from './increment-input/increment-input.component';
import { LoaderComponent } from './loader/loader.component';
import { PersonIntroComponent } from './person-intro/person-intro.component';
import { OptionDisplayPipe } from './pipes/option-display.pipe';
import { PreserveLineBreaksPipe } from './pipes/preserve-line-breaks.pipe';
import { ResponsiveNavComponent } from './responsive-nav/responsive-nav.component';
import { TitleComponent } from './title/title.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    ToastrModule.forRoot({
      timeOut: 7500,
      positionClass: 'inline',
      closeButton: true
    }),
    ToastContainerModule
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    AlertComponent,
    CategoryListComponent,
    CategoryMenuComponent,
    ColourSelectorComponent,
    LoaderComponent,
    ResponsiveNavComponent,
    OptionDisplayPipe,
    PreserveLineBreaksPipe,
    CssCarouselComponent,
    ShowSpinnerDirective,
    FeaturedProductsComponent,
    TitleComponent,
    IncrementInputComponent,
    FooterComponent
  ],
  declarations: [
    AlertComponent,
    CategoryListComponent,
    CategoryMenuComponent,
    LoaderComponent,
    ResponsiveNavComponent,
    OptionDisplayPipe,
    CssCarouselComponent,
    PreserveLineBreaksPipe,
    ShowSpinnerDirective,
    FeaturedProductsComponent,
    TitleComponent,
    AltusCategoryMenuComponent,
    PersonIntroComponent,
    AltusCategoryItemComponent,
    ColourSelectorComponent,
    IncrementInputComponent,
    FooterComponent,
    ColourSelectorItemComponent
  ],
})
export class SharedModule {
  constructor() { }
}
