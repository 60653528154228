<div class="host" [ngClass]="theme">
  <div fxFlexFill class="container">
    <div class="image">
      <img [src]="images[imageIndex].url">
    </div>
    <div class="detail">
      <a [routerLink]="['/']"><img [src]="logo" /></a>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>