<section class="carousel" aria-label="Gallery">
  <ol class="carousel__viewport">
    <ng-container *ngFor="let slide of slidesData">
      <li class="carousel__slide" [ngStyle]="{'background-image': 'url(\'' + slide.url + '\')'} ">
        <div class="carousel__snapper">
        </div>
      </li>
    </ng-container>
  </ol>
  <!-- <aside class="carousel__navigation">
    <ol class="carousel__navigation-list">
      <ng-container *ngFor="let slide of slidesData">
        <li class="carousel__navigation-item">
          <a class="carousel__navigation-button">x</a>
        </li>
      </ng-container>
    </ol>
  </aside> -->
</section>