import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Breadcrumb, CategoryModel, CategoryWithPath } from './../models';
import { HttpWrapperService } from './http-wrapper.service';

// https://www.bennadel.com/blog/3572-using-a-wild-card-route-to-traverse-arbitrarily-nested-data-in-angular-7-2-4.htm
// https://github.com/bennadel/JavaScript-Demos/tree/master/demos/router-folder-like-structure-angular7

@Injectable()
export class CategoryService {
  private _categories: CategoryWithPath[];
  public categories: Observable<CategoryWithPath[]>;

  private _currentCategory$: BehaviorSubject<CategoryWithPath> = new BehaviorSubject(null);
  public currentCategory$: Observable<CategoryWithPath> = this._currentCategory$.asObservable();

  private _breadcrumbs$: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject([]);
  public breadcrumbs$: Observable<Breadcrumb[]> = this._breadcrumbs$.asObservable();

  // used to close pop out menu in mobile view
  private _terminalClicked: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public terminalClicked$: Observable<boolean> = this._terminalClicked.asObservable();

  private root: string = 'catalogues';
  private rootParentId: number = 0;

  constructor(
    private HttpWrapperService: HttpWrapperService,
    private router: Router
  ) {
  }

  initialize() {
    let path = 'category/getAll';

    this.categories = this.HttpWrapperService.get(`${path}`)
      .pipe(
        map(categories => {
          this._categories = categories.map(c => { return ({ ...c, path: encodeURI(c.name) }) });
          this.initView();
          this.activatePath();
          return this._categories;
        }),
        shareReplay(1)
      );
  }

  clear() {
    this._categories = [];
    this._currentCategory$.next(null);
    this._breadcrumbs$.next([]);
    this._terminalClicked.next(false);
  }

  gotoCurrentCategory() {
    if (this._currentCategory$.value)
      this.categorySelected(this._currentCategory$.value)
  }

  setCurrentCategory(productCategory: CategoryModel) {
    if (!this._currentCategory$.value) {
      let category = this._categories.find(c => c.id === productCategory.id);
      if (category) {
        let paths = this.currentCategoryPath(category);
        this._currentCategory$.next(category)
        this.createBreadcrumbs(paths.splice(1));
      }
    }
  }

  private initView() {
    let paths = this.router.parseUrl(this.router.url).root.children.primary.segments.map(
      urlSegment => encodeURI(urlSegment.path)
    );
    if (paths.length > 1 && paths[1] === 'product') return;
    if (paths.length > 0 && paths[0] === this.root) {
      let categoryId = this.renderTree(paths.splice(1));
      this._currentCategory$.next(this._categories.find(c => c.cin7Id === categoryId))
    }
  }

  private activatePath() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url) {
        let paths = event.url.split('/');
        if (paths.length > 2 && paths[2] === 'product') return;
        if (paths.length > 1 && paths[1] === this.root) {
          let categoryId = this.renderTree(paths.splice(2));
          this._currentCategory$.next(this._categories.find(c => c.cin7Id === categoryId))
        }
      }
    });
  }

  categorySelected(category: CategoryWithPath, forceNav: boolean = false) {
    if (category.cin7ParentId !== this.rootParentId || forceNav) {
      const child = this._categories.find(c => c.cin7ParentId === category.cin7Id)
      if (child) {
        // this.categorySelected(child, forceNav)
      }
      else {
        this._currentCategory$.next(category);
        this._terminalClicked.next(true);
        this.router.navigate(this.currentCategoryPath(category));
      }
    }
    else {
      this._terminalClicked.next(false);
    }
  }

  private currentCategoryPath(category: CategoryWithPath): string[] {
    let path: string[] = [];
    path.push(category.name)
    while (category.cin7ParentId !== this.rootParentId) {
      category = this._categories.find(cat => cat.cin7Id === category.cin7ParentId);
      path.push(category.name)
    }
    path.push(this.root);
    return path.reverse();
  }

  private createBreadcrumbs(paths: string[]) {
    let runningPath = '/' + this.root;

    let breadcrumbs = paths.map(
      (path: string) => {
        runningPath += ('/' + path);
        return {
          name: decodeURI(path),
          path: runningPath
        };
      }
    );
    this._breadcrumbs$.next(breadcrumbs);
  }

  private getContextID(paths: string[]): number | null {
    let parentID = this.rootParentId;
    for (let path of paths) {
      let context = this._categories.find(cat => cat.cin7ParentId === parentID && cat.path === path);
      if (context) {
        parentID = context.cin7Id;
      } else {
        return (null);
      }
    }
    return parentID;
  }

  private renderTree(names: string[]): number {
    let parentID = this.getContextID(names);
    if (parentID === null) {
      this.router.navigate([this.root]);
      return parentID;
    }
    this.createBreadcrumbs(names);
    return parentID;
  }
}
