<mat-card>
    <alert></alert>
    <h1>Reset Password</h1>
    <p *ngIf="!submitted">Password reset instructions will be sent to the address you enter below.</p>
    <form [formGroup]="form" (ngSubmit)="submit()" class="form" autocomplete="false" *ngIf="!submitted">
        <formly-form [form]="form" [fields]="fields" [model]="model">
            <div class="form-element">
                <button mat-flat-button color="primary" type="submit" class="button" [appShowSpinner]="isSaving"
                    [disabled]="!form.valid">Reset Password</button>
            </div>
        </formly-form>
    </form>
    <p *ngIf="submitted">If the email address provided by you is available in our database, then you will receive
        further instructions in your email inbox.</p>
</mat-card>
<app-footer></app-footer>