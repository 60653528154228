import { Pipe, PipeTransform } from '@angular/core';
import { LineItem } from 'src/app/core/models';

@Pipe({
  name: 'optionDisplay'
})
export class OptionDisplayPipe implements PipeTransform {
  transform(value: LineItem): string {
    let s = "";
    let sep = "";
    if (value.option1) {
      s += value.option1;
      sep = ", ";
    }
    if (value.option3) {
      let size = value.option3.split(' x ');
      s += sep + 'Size: ' + size[size.length - 1].trim();
      sep = ", ";
    }
    if (s.length > 0)
      return `(${s})`;
  }
}
