import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TenantService } from '../services';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  // https://itnext.io/building-a-skinnable-multi-tenant-angular-application-7bc6f558fc0c
  constructor(private tenantService: TenantService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = this.tenantService.addTenantToHeaders(request.headers);

    request = request.clone({
      headers: headers
    });

    return next.handle(request);
  }
}