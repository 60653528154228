import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatInput, MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  template: `
    <textarea matInput
      [id]="id"
      [readonly]="to.readonly"
      [formControl]="formControl"
      [errorStateMatcher]="errorStateMatcher"
      [cols]="to.cols"
      [rows]="to.rows"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder"
      [tabindex]="to.tabindex || 0"
      [readonly]="to.readonly"
      [cdkTextareaAutosize]="false"
      [cdkAutosizeMinRows]="to.autosizeMinRows"
      [cdkAutosizeMaxRows]="to.autosizeMaxRows"
      [class.cdk-textarea-autosize]="false"
      >
    </textarea>
  `,
  providers: [
    // fix for https://github.com/ngx-formly/ngx-formly/issues/1688
    // rely on formControl value instead of elementRef which return empty value in Firefox.
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: TextareaAutoResizeComponent },
  ],
})
export class TextareaAutoResizeComponent extends FieldType implements OnInit {
  formControl: UntypedFormControl;
  @ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;
  defaultOptions = {
    templateOptions: {
      cols: 1,
      rows: 5,
    },
  };
}
