import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryModel } from 'src/app/core/models';

@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.css'],
  exportAs: 'categoryMenu'
})
export class CategoryMenuComponent {
  @Input() categories: CategoryModel[];
  @Output() categorySelected = new EventEmitter();

  constructor(
    private router: Router
  ) { }

  showCategory(category: CategoryModel) {
    this.categorySelected.emit();
    this.router.navigate(['/catalogues', category.name]);
  }
}

