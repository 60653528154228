import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationHistoryService {
  private route: string;

  constructor(private router: Router) { }

  public storeLastCatalogue() {
    let route = this.router.url;
    this.route = route.split('/').splice(0, 3).join('/');
  }

  public continueShopping() {
    if (this.route) {
      this.router.navigateByUrl(this.route)
    }
    else
      this.router.navigateByUrl('/home')

  }

}
