<ng-container *ngIf="colours.length>0">
  <p *ngIf="selectedColour">Colour: {{selectedColour.name}}</p>
  <p *ngIf="!selectedColour">Select colour</p>
  <div [ngStyle]="style">
    <ng-container *ngFor="let colour of codes">
      <app-colour-selector-item [primaryColour]="colour.primary" [secondaryColour]="colour.secondary"
        [selected]="selectedColour.code === colour.code" (click)="selectColour(colour)">
      </app-colour-selector-item>
    </ng-container>
  </div>
</ng-container>