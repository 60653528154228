import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-css-carousel',
  templateUrl: './css-carousel.component.html',
  styleUrls: ['./css-carousel.component.css']
})
export class CssCarouselComponent implements OnInit {
  //https://codepen.io/Schepp/pen/WNbQByE
  constructor() { }

  ngOnInit(): void {
  }

  slidesData: any = [
    { id: 1, title: 'image', url: '/assets/images/home/General Banner The Store.png' }
  ];

}
