import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerDetailsModel } from 'src/app/core/models';
import { CustomerService } from 'src/app/core/services';

@Component({
  selector: 'app-person-intro',
  templateUrl: './person-intro.component.html',
  styleUrls: ['./person-intro.component.css']
})
export class PersonIntroComponent implements OnInit {
  customer$: Observable<CustomerDetailsModel> = this.customerService.customerDetails$;

  constructor(
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
  }

}
