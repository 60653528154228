/** dependencies */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { TenantService } from 'src/app/core/services';
import { LayoutModule } from './../layout/layout.module';
/** configuration */
import { config } from './config';
import { FormlyService } from './formly-service';
import { TextareaAutoResizeComponent } from './templates/textarea-autoresize';
/** wrappers */
import { ErrorWrapperComponent } from './wrappers/error.component';
import { PanelWrapperComponent } from './wrappers/panel-wrapper.component';

// https://medium.com/hackernoon/organize-your-forms-application-using-ngx-fomrly-796150461045

export let myServiceFactory = () => { return { appearance: 'outline' }; };

// export let myServiceFactory = (tenantService: TenantService) => {
//   return tenantService.getTenant() === Tenant.Altus ? { appearance: 'outline' } : { appearance: 'outline' };
// };

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(config),
    FormlyMaterialModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    LayoutModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule
  ],
  providers: [
    FormlyService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useFactory: myServiceFactory, deps: [TenantService] },
    { provide: MAT_DATE_LOCALE, useValue: 'en-NZ' },
  ],
  declarations: [
    PanelWrapperComponent,
    ErrorWrapperComponent,
    TextareaAutoResizeComponent
    //   RepeatSectionComponent
  ]
})
export class UiFormModule { }
