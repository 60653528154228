import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthenticationService } from 'src/app/core/services';
import { FormlyService } from 'src/app/ui-form/formly-service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  public form = new UntypedFormGroup({});
  isSaving = false;
  submitted = false;
  returnUrl: string = '/';
  error = '';
  public fields: FormlyFieldConfig[] = [
    ...this.formlyService.getFormById('FORGOT_PASSWORD_FORM'),
  ];

  public model: any = { email: '' };

  constructor(
    private formlyService: FormlyService,
    private authenticationService: AuthenticationService
  ) { }

  submit() {
    if (this.isSaving) return;
    this.isSaving = true;
    this.authenticationService.forgotPassword(this.model)
      .subscribe(r => {
        this.isSaving = false;
        this.submitted = true;
      },
        err => {
          console.error(err)
          this.isSaving = false;
        })
  }

}
