import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'preserveLineBreaks'
})
export class PreserveLineBreaksPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace('\n', '<br/>');
  }
}
