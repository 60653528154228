import { Component, Input } from '@angular/core';
import { CategoryModel } from 'src/app/core/models';

@Component({
  selector: 'app-altus-category-item',
  templateUrl: './altus-category-item.component.html',
  styleUrls: ['./altus-category-item.component.css']
})
export class AltusCategoryItemComponent {
  @Input() isExpanded: boolean = false;
  @Input() forceExpanded: boolean = false;
  @Input() category: CategoryModel;

  constructor() { }

  showCategory() {
    this.isExpanded = !this.isExpanded;
  }
}
