import { Injectable } from '@angular/core';
import { FORMS_VALUES } from './schemas';

@Injectable()
export class FormlyService {
  public getFormById(id: string, disabled = false) {
    return FORMS_VALUES(disabled)[id];
  }

  private generateCleanConfiguration(clone: object[]) {
    return JSON.parse(JSON.stringify(clone));
  }
}