import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-colour-selector-item',
  templateUrl: './colour-selector-item.component.html',
  styleUrls: ['./colour-selector-item.component.css']
})
export class ColourSelectorItemComponent implements OnInit {
  @Input() primaryColour: string;
  @Input() secondaryColour: string;
  @Input() selected: boolean;
  public style: { [klass: string]: any } = {};

  constructor() { }

  ngOnInit(): void {
    if (this.secondaryColour && this.secondaryColour != this.primaryColour) {
      this.style['background'] = `linear-gradient(to right, ${this.primaryColour} 0%, ${this.primaryColour} 50%, ${this.secondaryColour} 50%, ${this.secondaryColour} 100%)`
    }
    else {
      this.style['background-color'] = `${this.primaryColour}`
    }
  }
}
