<ng-container *ngIf="(categories | async) as cats">
  <ng-container *ngIf="cats.length > 0">
    <mat-nav-list *ngIf="isExpanded" [ngClass]="isChild ? 'child' : ''">
      <ng-container *ngFor="let category of cats; let first=first">
        <app-altus-category-item [category]="category" [isExpanded]="first" (click)="clicked($event, category)" [forceExpanded]="false && (hasChildren(category) | async)">
        </app-altus-category-item>
      </ng-container>
    </mat-nav-list>
  </ng-container>
</ng-container>
