import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';


@Component({ selector: 'alert', templateUrl: 'alert.component.html' })
export class AlertComponent implements AfterViewInit {
    @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

    constructor(private toastr: ToastrService) { }

    ngAfterViewInit(): void {
        this.toastr.overlayContainer = this.toastContainer;
    }
}