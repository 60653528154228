<ng-container *ngFor="let category of categories">
  <ng-container *ngIf="category.children && category.children.length > 0">
    <button mat-menu-item color="primary" [matMenuTriggerFor]="menu" (click)="showCategory(category)">
      <span>{{category.name}}<mat-icon>arrow_drop_down</mat-icon></span>
    </button>
    <mat-menu #menu="matMenu">
      <span *ngFor="let child of category.children">
        <button mat-menu-item (click)="showCategory(child)">
          <span>{{child.name}}</span>
        </button>
      </span>
    </mat-menu>
  </ng-container>
  <ng-container *ngIf="!category.children || category.children.length === 0">
    <button mat-menu-item (click)="showCategory(category)">
      <span>{{category.name}}</span>
    </button>
  </ng-container>
</ng-container>