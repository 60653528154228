import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressModel } from './../models';
import { HttpWrapperService } from './http-wrapper.service';

const path = 'address';

@Injectable({ providedIn: 'root' })
export class AddressService {
  constructor(private HttpWrapperService: HttpWrapperService) {
  }

  get(): Observable<AddressModel[]> {
    return this.HttpWrapperService.get(`${path}/get`);
  }

  delete(address: AddressModel): Observable<boolean> {
    return this.HttpWrapperService.delete(`${path}/delete/${address.id}`);
  }

}
