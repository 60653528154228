import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from './http-wrapper.service';
import { CreditNoteModel } from '../models/credit-note';

const path = 'creditNote';

@Injectable()
export class CreditNoteService {
  constructor(private HttpWrapperService: HttpWrapperService) { }

  getSubmitted(): Observable<CreditNoteModel[]> {
    return this.HttpWrapperService.get(`${path}/getSubmitted`);
  }
}
