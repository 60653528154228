
export const VIEW_AS_FORM = (disabled = false) => ({
  id: 'VIEW_AS_FORM',
  template: [
    {
      wrappers: ['panel'],
      templateOptions: {
        label: 'Formly'
      },
      fieldGroup: [
        {
          key: 'username',
          type: 'input',
          templateOptions: {
            label: 'Username',
            placeholder: 'Username',
            required: true
          }
        }
      ],
    },
  ]
});
